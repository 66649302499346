















































import { mapGetters } from 'vuex'
import { Component, PropType } from 'vue'
import ToolTip from './ToolTip.vue'
import TextInput from './TextInput.vue'
import { Answer, AnswerValue, AnswerOption, Question } from '@typesCustom/index'

export default TextInput.extend({
  components: {
    ToolTip,
  },
  props: {
    parent: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    options: {
      type: Array as PropType<AnswerOption[]>,
      required: true,
    },
    inputTypes: {
      type: Object as PropType<{ key: Component }>,
      required: true,
    },
  },
  computed: mapGetters(['optionVisuals', 'uiText']),
  data() {
    return {
      selected: '' as AnswerValue,
      hasSub: false,
      subSelected: false,
      subQuestion: undefined as Question | undefined,
    }
  },
  methods: {
    visual(value: AnswerValue): string {
      const active = this.selected === value
      const visuals = this.optionVisuals(this.id, active)
      return visuals ? visuals[String(value)] : ''
    },
    selectAnswer(value: AnswerValue, nextQuestion: Question | undefined) {
      if (this.hasSub && value === this.selected) {
        this.previous()
        return
      }

      this.selected = value
      this.hasSub = Boolean(nextQuestion)

      if (this.hasSub) {
        this.$emit('select', true)
        this.subQuestion = nextQuestion
        if (nextQuestion && nextQuestion.required) {
          this.$store.dispatch('require', nextQuestion.id)
        }
      }
      this.$store.dispatch('saveAnswer', { id: this.id, value: this.selected })
      this.$emit('change', { id: this.id, value: this.selected })
    },
    previous() {
      if (this.parent.length) {
        this.$store.dispatch('deleteAnswer', { id: this.id, value: this.selected })
        this.$store.dispatch('unRequire', this.id)
      }
      this.selected = ''
      this.hasSub = false
      this.subSelected = false
      this.subQuestion = undefined
      this.$emit('select', false)
    },
    notifyParent(value: boolean) {
      this.subSelected = value
      if (!value) {
        this.$store.dispatch('deleteAnswer', { id: this.id, value: this.selected })
        window.innerWidth < 768 ? this.previous() : (this.selected = '')
      }
    },
    prefill() {
      const answer: Answer = this.answers.find((x: Answer) => x.id === this.id)
      const input: AnswerValue = answer ? answer.value : this.default
      const option: AnswerOption | undefined = this.options.find((x: AnswerOption) => x.value === input)
      if (option) {
        const { value, nextQuestion } = option
        this.selectAnswer(value, nextQuestion)
      }
    },
  },
  created() {
    this.prefill()
  },
})
