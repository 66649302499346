var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"question-single",class:{ slide: _vm.hasSub && _vm.selected }},[_c('div',{staticClass:"question"},[(_vm.parent.length)?_c('a',{staticClass:"streamer",attrs:{"href":"/"},on:{"click":function($event){$event.preventDefault();return _vm.previous.apply(null, arguments)}}},[_vm._v("< "+_vm._s(_vm.uiText('vraag-terug')))]):_c('span',{staticClass:"streamer"},[_vm._v(" ")]),_c('h4',{staticClass:"has-tooltip",attrs:{"id":("label-" + _vm.id)}},[(_vm.selected !== '')?_c('span',{staticClass:"icon has-text-primary"},[_c('i',{staticClass:"fa fa-lg fa-check",attrs:{"aria-hidden":"true"}})]):_vm._e(),_c('span',{staticClass:"question-title"},[_vm._v(_vm._s(_vm.displayText(_vm.id)))]),_c('tool-tip',{attrs:{"id":_vm.id}})],1),_c('div',{staticClass:"level-left"},_vm._l((_vm.options),function(ref){
var value = ref.value;
var nextQuestion = ref.nextQuestion;
return _c('div',{key:value,staticClass:"card answer",class:{
          'is-selected': value === _vm.selected,
          'has-sub-selected': _vm.subSelected,
          'has-sub': _vm.hasSub,
        },style:({ backgroundImage: ("url('" + (_vm.visual(value)) + "')") }),attrs:{"tabindex":"0","role":"checkbox","name":_vm.name,"value":value,"aria-labelledby":("label-" + _vm.id),"aria-checked":value === _vm.selected},on:{"click":function($event){$event.stopPropagation();return _vm.selectAnswer(value, nextQuestion)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();return _vm.selectAnswer(value, nextQuestion)}}},[_c('label',[_vm._v(_vm._s(_vm.optionText(_vm.id, value)))])])}),0)]),(_vm.subQuestion)?_c(_vm.inputTypes[_vm.subQuestion.format],_vm._b({tag:"component",attrs:{"parent":_vm.parent.concat( [((_vm.displayText(_vm.id)) + ": " + (_vm.optionText(_vm.id, _vm.selected)))]),"input-types":_vm.inputTypes},on:{"select":_vm.notifyParent}},'component',_vm.subQuestion,false)):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }